import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguagesPopoverPageModule } from './pages/languages-popover/languages-popover.module';
import { VideoPlayerModalPageModule } from './pages/inside-app/galery/videos/modal/video-player-modal.module';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { faFolder, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { NotificationFilterPage } from './components/notification-filter/notification-filter';
import { JpImagePreloadModule } from '@jaspero/ng-image-preload';

import { NgxPopperModule } from 'ngx-popper';

import { TooltipsModule } from 'ionic4-tooltips';
import { Network } from '@ionic-native/network/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    GalleryModule,
    LightboxModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LanguagesPopoverPageModule,
    HttpClientModule,
    LanguagesPopoverPageModule,
    FormsModule,
    VideoPlayerModalPageModule,
    GalleryModule,
    LightboxModule,
    JpImagePreloadModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    TranslateModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    NgxPopperModule.forRoot({
      // disableDefaultStyling: true
    }),
    TooltipsModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent, NotificationFilterPage],
  providers: [InAppBrowser, SplashScreen, StatusBar, StreamingMedia, Network],
  bootstrap: [AppComponent],
  entryComponents: [NotificationFilterPage]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFolder, faTrophy);
    // library.addIconPacks(fas, fab, far);
  }
}
