import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAuth } from './providers/check-auth.service';
import { CheckNotAuth } from './providers/check-notAuth.service';
import { CheckLang } from './providers/check-lang';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/authentification/welcome-page/welcome-page.module').then(m => m.WelcomePagePageModule),
    canLoad: [CheckNotAuth]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/inside-app/account/account.module').then(m => m.AccountModule),
    canLoad: [CheckAuth]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/authentification/login/login.module').then(m => m.LoginModule),
    canLoad: [CheckNotAuth, CheckLang]
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/authentification/signup/signup.module').then(m => m.SignUpModule),
    canLoad: [CheckNotAuth, CheckLang]
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/inside-app/tabs-page/tabs-page.module').then(m => m.TabsModule),
    canLoad: [CheckAuth]
    // loadChildren: () => import('./router/redirect routings/redirect.module').then(m => m.RedirectModule),
  },
  {
    path: 'languages-popover',
    loadChildren: () => import('./pages/languages-popover/languages-popover.module').then(m => m.LanguagesPopoverPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/authentification/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
    canLoad: [CheckNotAuth, CheckLang]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
