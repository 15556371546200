import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StaticDataService } from './staticData/static-data.service';
import { ResultsService } from './results/results.service';


@Injectable({
  providedIn: 'root'
})
export class UserData {
  LNG_KEY = 'SELECTED_LANGUAGE';

  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
  public userData$ = new BehaviorSubject<any>(null);
  public currentUserData$ = this.userData$.asObservable();
  constructor(
    public storage: Storage,
    private menu: MenuController,
    private readonly http: HttpClient,
    private readonly staticDataService: StaticDataService,
  ) {
    Promise.all([this.isLoggedIn(), this.getUsername()]).then(value => {
      if (value[0] === true) {
        this.changeUserData(value[1]);
      }
    });
  }

  register(registerData: {}) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'auth', registerData);
  }

  changeUserData(data) {
    this.userData$.next(data);
  }
  hasFavorite(sessionName: string): boolean {
    return (this.favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }



  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  login(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      this.changeUserData(username);
    });
  }

  signup(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
    });
  }

  async logout(): Promise<any> {
    await this.menu.close();
    await this.menu.enable(false);
    await this.changeUserData(null);
    const lastLng = this.storage.get(this.LNG_KEY);
    await this.storage.clear();
    await this.storage.set(this.LNG_KEY, lastLng);
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set('username', username);
  }

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }

  createNewUser(postData: {}) {
    // tslint:disable-next-line:max-line-length
    const url = this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'register';
    return this.http.post(url, postData);
  }

  confirmNewUser(postData: {}) {
    // tslint:disable-next-line:max-line-length
    const url = this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'authVerifPin';
    return this.http.post(url, postData);
  }


  getCountries() {
    return new Promise(resolve => {
      this.http.get(this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + 'countries').subscribe(data => {
        resolve(data);
      },
        err => {
          console.log(err);
        });
    });
  }
}
