import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { GlobalService } from '../global/global.service';
import { HttpService } from '../httpService/http.service';

import { of } from 'rxjs';
import { mergeMap, groupBy, reduce, map, take } from 'rxjs/operators';
import { StaticDataService } from '../staticData/static-data.service';
import { isNull } from 'util';
import { TranslateFromApiService } from '../translate/translate-from-api.service';
import { interval, Subscription } from 'rxjs';
import { CommonService } from './../commonService/common-service.service';
import { LanguageService } from '../languages/language.service';
@Injectable({
  providedIn: 'root'
})
export class ResultsService {
  subscription: Subscription;

  public sportType$ = new BehaviorSubject<any>(null);
  public currentSportType$ = this.sportType$.asObservable();

  public subEvent$ = new BehaviorSubject<any>(null);
  public currentSubEvent$ = this.subEvent$.asObservable();

  public categorySearch$ = new BehaviorSubject<any>('live');
  public currentCategorySearch$ = this.categorySearch$.asObservable();

  public sports$ = new BehaviorSubject<any>(null);
  public currentAllSports$ = this.sports$.asObservable();

  public Events$ = new BehaviorSubject<any>(null);
  public currentEvents$ = this.Events$.asObservable();

  public ranking$ = new BehaviorSubject<any>(null);
  public currentRanking$ = this.ranking$.asObservable();

  public results$ = new BehaviorSubject<any>(null);
  public currentResults$ = this.results$.asObservable();

  public calendar$ = new BehaviorSubject<any>(null);
  public currentCalendar$ = this.calendar$.asObservable();

  public live$ = new BehaviorSubject<any>(null);
  public currentLive$ = this.live$.asObservable();
  timeOutForLive = 10;
  sports: any;
  events: any;
  erreur = false;
  tablesByEventBody = {
    idsportEvent: null,
    width: '20'
  };
  liveInterval;
  lng;
  public resultsList: any = [];
  public calendarList: any = [];
  public liveList: any = [];

  constructor(
    private translateFromApiService: TranslateFromApiService,
    public globalService: GlobalService,
    public httpService: HttpService,
    public languagesService: LanguageService,
    private readonly staticDataService: StaticDataService,
    private readonly commonService: CommonService,
  ) {
    this.languagesService.currentLng$.subscribe((currentLng) => {
      this.lng = currentLng;
      console.log('this.sports$.value', this.sports$.value);
      console.log('currentLng', currentLng);
      if (currentLng !== null && this.sports$.value !== null) {
        this.loadingDataSports();
      }
    });
  }

  async changeSportType(data) {
    this.sportType$.next(data);
    await this.loadingEventsByIDSport();
  }

  changeSubEvent(data) {
    this.resultsList = [];
    this.calendarList = [];
    this.liveList = [];
    this.subEvent$.next(data);
  }

  changeCategorySearch(data) {
    this.categorySearch$.next(data);
  }

  async loadingDataSports() {
    const sports = await this.globalService.getKey('sports');
    if (sports) {
      await this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
        this.sports = this.translateFromApiService.translate(lngLocal, sports);
      });
      this.changeAllSports(this.sports);
      await this.changeSportType(sports[0].id);
    } else {
      // this.globalService.showLoader('Loading ...');
      const reqSports = await this.httpService.post('sports', { width: 50 });
      await forkJoin([reqSports]).subscribe(async (results: any) => {
        console.log(results, 'resultsresultsresults');
        await this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
          this.sports = this.translateFromApiService.translate(lngLocal, results[0]);
        });
        this.sports = results[0];
        await this.changeSportType(this.sports[0].id);
        await this.globalService.setKey('sports', results[0]);
        await this.changeAllSports(results[0]);

      }, async error => {
        if (!this.erreur) {
          if (error.status === 401) {
            this.globalService.showSessionExpiredAlertAndLogOut();
            await this.globalService.dismissLoader();
          }
          this.commonService.handleError(error);
        } else {
          this.erreur = true;
        }
      });
    }
  }

  changeAllSports(data) {
    this.sports$.next(data);
  }

  async loadingEventsByIDSport() {
    try {
      const sports = await this.globalService.getKey('sports');
      console.log('sport do bug when logout anothorized', sports);
      console.log('this.sportType$.getValue()', this.sportType$.getValue());
      if (sports !== null) {
        const sport = await sports.filter(x => x.id === Number(this.sportType$.getValue()));
        let events = await this.globalService.getKey('events-' + sport[0].id);
        if (events) {
          await this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
            events = this.translateFromApiService.translate(lngLocal, events);
          });
          this.changeEventsBySportID(events);
          if (this.subEvent$.getValue() === null) {
            this.changeSubEvent(events[0].id);
          }
          if (this.sportType$.getValue() !== 'football' && events.length > 0) {
            this.changeSubEvent(events[0].id);
          }
        } else {
          const bodyEvents = {
            idsport: sport[0].id
          };
          const reqSports = await this.httpService.post('events', bodyEvents);
          await forkJoin([reqSports]).subscribe(async (results: any) => {
            await this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
              results[0] = this.translateFromApiService.translate(lngLocal, results[0]);
            });
            if (results[0].length > 0) {
              this.events = results[0];
              console.log(results[0], 'events by here 2');
              await this.globalService.setKey('events-' + sport[0].id, results[0]);
              await this.changeEventsBySportID(results[0]);
            }
            await this.changeSubEvent(this.events[0].id);
          }, async error => {
            if (!this.erreur) {
              if (error.status === 401) {
                this.globalService.showSessionExpiredAlertAndLogOut();
                await this.globalService.dismissLoader();
                this.commonService.handleError(error);
              }
            } else {
              this.erreur = true;
            }
          });
        }
      }

    } catch (error) {
      console.log(error, 'error');

    }

  }

  changeEventsBySportID(data) {
    this.Events$.next(data);
  }

  async loadDataRanking() {
    try {
      const events = await this.globalService.getKey('events-' + this.sportType$.getValue());
      const event = await events.filter(x => x.id === Number(this.subEvent$.getValue()));
      this.tablesByEventBody.idsportEvent = event[0].id;
      const reqRanking = await this.httpService.post('tablesByEvent', this.tablesByEventBody);
      forkJoin([reqRanking]).subscribe((results: any) => {
        this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
          results = this.translateFromApiService.translate(lngLocal, results);
          const groupByq = (array, key) => {
            return array.reduce((result, currentValue) => {
              // If an array already present for key, push it to the array. Else create an array and push the object
              (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
              );
              // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
              return result;
            }, {}); // empty object is the initial value for result object
          };

          const resultGroupedBy = groupByq(results[0][0].stats, 'name');
          // console.log(resultGroupedBy, 'before reform');

          let i = 0;
          const formatArray = [];
          // tslint:disable-next-line:forin
          for (const key in resultGroupedBy) {
            const item = {
              id: null,
              isHeadline: null,
              name: null,
              array: null
            };
            const value = resultGroupedBy[key];
            i++;
            item.id = i;
            item.name = key;
            item.isHeadline = value[0].isHeadline;
            item.array = value;
            formatArray.push(item);
          }
          results[0][0].stats = formatArray.sort((one, two) => (one.name > two.name ? 1 : -1));
        });
        this.changeRanking(results[0][0]);
      }, async error => {
        if (!this.erreur) {
          if (error.status === 401) {
            this.globalService.showSessionExpiredAlertAndLogOut();
            await this.globalService.dismissLoader();
            this.commonService.handleError(error);
          }
        } else {
          this.erreur = true;
        }
      });
    } catch (error) {

    }


  }
  changeRanking(data) {
    this.ranking$.next(data);
  }

  async loadingResultsByIDEvent(indexStart?, offset?, event?) {
    try {
      if (!indexStart && offset) {
        this.resultsList = null;
      }
      const sports = await this.globalService.getKey('sports');
      const sport = await sports.filter(x => x.id === Number(this.sportType$.getValue()));
      const events = await this.globalService.getKey('events-' + sport[0].id);
      const getId = await events.filter(x => x.id === Number(this.subEvent$.getValue()));
      console.log('getId', getId, this.subEvent$.getValue());

      if (!indexStart) {
        indexStart = 0;
      }
      if (!offset) {
        offset = 10;
      }
      const bodyEvents = {
        idsportEvent: getId[0].id,
        locale: this.staticDataService.settings.home.lgn,
        offset: indexStart,
        limit: offset,
        width: this.staticDataService.settings.match.width,
        ended: this.staticDataService.settings.match.ended
      };

      const reqSports = await this.httpService.post('all-matches', bodyEvents);
      await forkJoin([reqSports]).subscribe(async (results: any) => {
        this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
          results[0] = this.translateFromApiService.translate(lngLocal, results[0]);
          if (results[0].length === 0 && this.calendarList.length > 0 && event) {
            event.target.disabled = true;
            this.globalService.presentToast('No More Data');
          }
          if (results[0].length === 0 && !event) {
            this.resultsList = [];
            this.changeResultByIDEvent(this.resultsList);
            return;
          } else {
            this.resultsList = this.resultsList.concat(results[0]);
            this.changeResultByIDEvent(this.resultsList);
          }
        });
      }, async error => {
        if (!this.erreur) {
          if (error.status === 401) {
            this.globalService.showSessionExpiredAlertAndLogOut();
            await this.globalService.dismissLoader();
            this.commonService.handleError(error);
          }
        } else {
          this.erreur = true;
        }
      });

    } catch (error) {
      console.log(error, 'error');
    }

  }

  changeResultByIDEvent(data) {
    this.results$.next(data);
  }

  async loadingCalendarByIDEvent(indexStart?, offset?, event?) {
    try {
      if (!indexStart && offset) {
        this.calendarList = null;
      }
      const sports = await this.globalService.getKey('sports');
      const sport = await sports.filter(x => x.id === Number(this.sportType$.getValue()));
      const events = await this.globalService.getKey('events-' + sport[0].id);
      const getId = await events.filter(x => x.id === Number(this.subEvent$.getValue()));
      if (!indexStart) {
        indexStart = 0;
      }
      if (!offset) {
        offset = 10;
      }

      const bodyCalendars = {
        idsportEvent: getId[0].id,
        locale: this.staticDataService.settings.home.lgn,
        offset: indexStart,
        limit: offset,
        width: this.staticDataService.settings.match.width,
        ended: this.staticDataService.settings.match.notEnded
      };

      const reqbodyCalendars = await this.httpService.post('all-matches', bodyCalendars);
      await forkJoin([reqbodyCalendars]).subscribe(async (results: any) => {
        this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
          results[0] = this.translateFromApiService.translate(lngLocal, results[0]);
          if (results[0].length === 0 && this.calendarList.length > 0 && event) {
            event.target.disabled = true;
            this.globalService.presentToast('No More Data');
          }
          if (results[0].length === 0 && !event) {
            this.calendarList = [];
            this.changeCalendarByIDEvent(this.calendarList);
            return;
          } else {
            this.calendarList = this.calendarList.concat(results[0]);
            this.changeCalendarByIDEvent(this.calendarList);
          }
        });
      }, async error => {
        if (!this.erreur) {
          if (error.status === 401) {
            this.globalService.showSessionExpiredAlertAndLogOut();
            await this.globalService.dismissLoader();
            this.commonService.handleError(error);
          }
        } else {
          this.erreur = true;
        }
      });

    } catch (error) {
      console.log(error, 'error');
    }

  }

  changeCalendarByIDEvent(data) {
    this.calendar$.next(data);
  }


  async loadingLiveByIDEvent() {
    try {
      this.loadMatchLive();
      this.liveInterval = interval(30000);
      this.subscription = this.liveInterval.subscribe(async () => {
        this.loadMatchLive();
      }
      );
    } catch (error) {
      console.log(error, 'error');
    }

  }

  changeLiveByIDEvent(data) {
    this.live$.next(data);
  }

  clearInterval() {
    this.subscription.unsubscribe();
  }

  async loadMatchLive(indexStart?, offset?, event?) {
    try {
      if (!indexStart && offset) {
        this.liveList = null;
      }
      const sports = await this.globalService.getKey('sports');
      const sport = await sports.filter(x => x.id === Number(this.sportType$.getValue()));
      const events = await this.globalService.getKey('events-' + sport[0].id);
      const getId = await events.filter(x => x.id === Number(this.subEvent$.getValue()));
      if (!indexStart) {
        indexStart = 0;
      }
      if (!offset) {
        offset = 1000;
      }

      const bodyLiveEvents = {
        idsportEvent: getId[0].id,
        locale: this.staticDataService.settings.home.lgn,
        offset: indexStart,
        limit: offset,
        width: this.staticDataService.settings.match.width,
        ended: this.staticDataService.settings.match.notEnded,
        started: this.staticDataService.settings.match.started
      };

      const reqLives = await this.httpService.post('all-matches', bodyLiveEvents);
      await forkJoin([reqLives]).subscribe(async (results: any) => {
        console.log('here', results[0]);

        this.globalService.getKey('SELECTED_LANGUAGE').then((lngLocal: string) => {
          results[0] = this.translateFromApiService.translate(lngLocal, results[0]);
          if (results[0].length === 0 && this.liveList.length > 0 && event) {
            event.target.disabled = true;
            this.globalService.presentToast('No More Data');
          }
          if (results[0].length === 0 && !event) {
            this.liveList = [];
            this.changeLiveByIDEvent(this.liveList);
            return;
          } else {
            this.liveList.forEach(elementInLocal => {
              results[0] = results[0].filter(item => item.id !== elementInLocal.id);
            });
            this.liveList = this.liveList.concat(results[0]);
            this.changeLiveByIDEvent(this.liveList);
          }
        });
      }, async error => {
        if (!this.erreur) {
          if (error.status === 401) {
            this.globalService.showSessionExpiredAlertAndLogOut();
            await this.globalService.dismissLoader();
            this.commonService.handleError(error);
          }
        } else {
          this.erreur = true;
        }
      });

    } catch (error) {
      console.log(error, 'error');
    }
    // const bodyEvents = {
    //   width: this.staticDataService.settings.match.width
    // };
    // const reqSports = await this.httpService.post('matchesLive', bodyEvents);
    // await forkJoin([reqSports]).subscribe(async (results: any) => {
    //   this.liveList = results[0];
    //   await this.changeLiveByIDEvent(this.liveList);
    // }, async error => {
    //   if (!this.erreur) {
    //     if (error.status === 401) {
    //       this.globalService.showSessionExpiredAlertAndLogOut();
    //       await this.globalService.dismissLoader();
    //       this.commonService.handleError(error);
    //     }
    //   } else {
    //     this.erreur = true;
    //   }
    // });
  }
}
