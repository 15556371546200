import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { Platform, ToastController, ModalController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { UserData } from './providers/user-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalService } from './providers/global/global.service';
import { LanguageService } from './providers/languages/language.service';
import { NotificationFilterPage } from './components/notification-filter/notification-filter';
import { ResultsService } from './providers/results/results.service';
import { StaticDataService } from './providers/staticData/static-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  appPages = [];
  loggedIn = false;
  dark = false;
  excludedNotifications: any = [];
  sports: any;
  events: any;
  sportType;
  subEvent;
  categorySearch;
  LNG_KEY = 'SELECTED_LANGUAGE';
  lang = null;
  public isActiveTousLesFoot = false;
  public isActiveFootInternational = false;
  public isActiveDivers = false;
  public isActiveMercato = false;
  private authenticated$: BehaviorSubject<boolean>;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    public languagesService: LanguageService,
    public globalService: GlobalService,
    public modalCtrl: ModalController,
    public resultsService: ResultsService,
    private route: ActivatedRoute,
    public staticDataService: StaticDataService,
  ) {
    this.initializeApp();
  }

  async ngOnInit() {

    this.resultsService.currentSportType$.subscribe((currentSportType) => {
      this.sportType = currentSportType;
    });

    this.resultsService.currentSubEvent$.subscribe((currentSubEvent) => {
      this.subEvent = currentSubEvent;
    });

    // await this.resultsService.loadingDataSports();
    this.appPages = [];
    this.resultsService.currentAllSports$.subscribe((sports) => {

      this.globalService.getKey(this.LNG_KEY).then((lng) => {
        this.lang = lng;
      });

      const appPages = this.staticDataService.settings.appPages.slice(0);
      console.log('sports', sports);

      let i = 1;
      if (sports !== null) {
        this.globalService.setKey('sports', sports);
        if (sports.length > 0 && appPages.length === 4) {
          sports.forEach(sport => {
            console.log('sport', sport);
            if (sport.showInMenu) {
              sport.open = false;
              sport.title = sport.name;
              sport.imgFromUrl = this.staticDataService.settings.server + sport.picture;
              appPages.splice(i, 0, sport);
              i++;
            }
          });
          const otherSport = {
            title: 'sport.OTHERSPORTS',
            icon: 'ellipsis-horizontal',
            url: this.staticDataService.settings.newsUrl + '16',
            open: null,
            id: null,
          };
          appPages.splice(i, 0, otherSport);
        }
      }

      console.log(appPages, 'appPages');
      this.appPages = appPages;
      this.sports = sports;
    });

    this.resultsService.currentEvents$.subscribe((events) => {
      this.events = events;
    });

    const sportId = this.route.snapshot.paramMap.get('sportId');
    this.sportType = sportId;
    const subEventId = this.route.snapshot.paramMap.get('eventId');
    this.subEvent = subEventId;
    const categorySearch = this.route.snapshot.paramMap.get('categorySearch');
    this.categorySearch = categorySearch;
    console.log(sportId, 'sportId');
    this.userData.currentUserData$.subscribe(() => {
      this.loggedIn = true;
    });
    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  public getAuthentication(): Observable<boolean> {
    return this.authenticated$.asObservable();
  }

  async presentFilter() {
    await this.globalService.getKey('notifications').then((dataNotification) => {
      console.log(dataNotification, 'datadata');
      if (dataNotification) {
        this.excludedNotifications = dataNotification;
      }
    }

    );
    const modal = await this.modalCtrl.create({
      component: NotificationFilterPage,
      swipeToClose: true,
      componentProps: { excludedNotifications: this.excludedNotifications }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data) {
      await this.globalService.setKey('notifications', this.excludedNotifications);
      this.excludedNotifications = this.excludedNotifications;
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      const lng = await this.globalService.getKey(this.LNG_KEY);
      if (lng) {
        this.lang = lng;
        this.languagesService.setLanguage(lng);
      }
    });
  }

  logout() {
    this.userData.logout().then(() => {
      this.clearBoolean();
      return this.router.navigateByUrl('');
    });
  }

  onChangeSports(sport) {
    this.clearBoolean();
    console.log(sport, 'sport');
    this.resultsService.changeSportType(sport);
  }
  goToTousLesFoot() {
    this.clearBoolean();
    this.isActiveTousLesFoot = !this.isActiveTousLesFoot;
    this.router.navigateByUrl(this.staticDataService.settings.newsUrl + '1', { queryParams: { chekNotEvent: 1 } });
  }

  goToFootInternational() {
    this.clearBoolean();
    this.isActiveFootInternational = !this.isActiveFootInternational;
    this.router.navigateByUrl(this.staticDataService.settings.newsUrl + '1/1', { queryParams: { chekNotEvent: 1 } });
  }
  goToDivers() {
    this.clearBoolean();
    this.isActiveDivers = !this.isActiveDivers;
    this.router.navigateByUrl(this.staticDataService.settings.newsUrl + '1/11', { queryParams: { chekNotEvent: 1 } });
  }
  goToMercato() {
    this.clearBoolean();
    this.isActiveMercato = !this.isActiveMercato;
    this.router.navigateByUrl(this.staticDataService.settings.newsUrl + '1/12', { queryParams: { chekNotEvent: 1 } });
  }
  clearBoolean() {
    console.log('aaaa');

    this.isActiveFootInternational = false;
    this.isActiveTousLesFoot = false;
    this.isActiveDivers = false;
    this.isActiveMercato = false;
  }
}
